import { Modal, Form, Input, Select, Space, Popover } from 'antd';
import { useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import { Template } from 'src/interfaces/template-interface';
import { SUPPORTED_TEMPLATE_ICONS, DEFAULT_COLORS } from '../constants';
import './TemplateModal.scss';

interface TemplateModalProps {
  visible: boolean;
  template: Template | null;
  onCancel: () => void;
  onSubmit: (values: any) => void;
}

const TemplateModal = ({ visible, template, onCancel, onSubmit }: TemplateModalProps) => {
  const [form] = Form.useForm();
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [currentColor, setCurrentColor] = useState(DEFAULT_COLORS[0]);

  useEffect(() => {
    if (visible && template) {
      form.setFieldsValue({
        name: template.name,
        description: template.description,
        content: template.content,
        isPrivate: template.isPrivate,
        icon: template.icon,
        color: template.color,
        tags: template.tags,
      });
      setCurrentColor(template.color || DEFAULT_COLORS[0]);
    } else {
      form.resetFields();
      setCurrentColor(DEFAULT_COLORS[0]);
    }
  }, [visible, template, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      onSubmit({ ...values, color: currentColor });
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const handleColorChange = (color: any) => {
    setCurrentColor(color.hex);
    form.setFieldValue('color', color.hex);
  };

  const colorPickerContent = (
    <div>
      <ChromePicker
        color={currentColor}
        onChange={handleColorChange}
        disableAlpha
      />
      <div className="preset-colors">
        {DEFAULT_COLORS.map((color) => (
          <div
            key={color}
            className="preset-color"
            style={{ backgroundColor: color }}
            onClick={() => handleColorChange({ hex: color })}
          />
        ))}
      </div>
    </div>
  );

  return (
    <Modal
      title={template ? 'Edit Template' : 'Create Template'}
      open={visible}
      onCancel={onCancel}
      onOk={handleSubmit}
      okText={template ? 'Update' : 'Create'}
      width={600}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          isPrivate: false,
          tags: [],
          color: DEFAULT_COLORS[0],
        }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please enter template name' }]}
        >
          <Input placeholder="Enter template name" />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: 'Please enter template description' }]}
        >
          <Input.TextArea rows={3} placeholder="Enter template description" />
        </Form.Item>

        <Form.Item
          name="content"
          label="Content"
          rules={[{ required: true, message: 'Please enter template content' }]}
        >
          <Input.TextArea rows={6} placeholder="Enter template content" />
        </Form.Item>

        <Form.Item name="isPrivate" label="Privacy">
          <Select>
            <Select.Option value={false}>Public</Select.Option>
            <Select.Option value={true}>Private</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="icon"
          label="Icon"
          rules={[{ required: true, message: 'Please select an icon' }]}
        >
          <Select placeholder="Select an icon">
            {SUPPORTED_TEMPLATE_ICONS.map((iconOption) => {
              const Icon = iconOption.icon;
              return (
                <Select.Option key={iconOption.value} value={iconOption.value}>
                  <Space>
                    <Icon />
                    {iconOption.label}
                  </Space>
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name="color"
          label="Color"
          rules={[{ required: true, message: 'Please select a color' }]}
        >
          <div className="color-picker-container">
            <Popover
              content={colorPickerContent}
              trigger="click"
              open={showColorPicker}
              onOpenChange={setShowColorPicker}
            >
              <div
                className="color-preview"
                style={{ backgroundColor: currentColor }}
              >
                <span className="color-value">{currentColor}</span>
              </div>
            </Popover>
          </div>
        </Form.Item>

        <Form.Item name="tags" label="Tags">
          <Select mode="tags" placeholder="Add tags">
            {/* You can add predefined tags here if needed */}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TemplateModal; 