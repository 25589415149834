import { useEffect, useState } from 'react';
import { Button, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { BaseText } from 'src/components/text';
import { useAppDispatch } from 'src/stores';
import { showSuccessToast, showErrorToast } from 'src/components/toast/Toast';
import ConfirmModal from 'src/components/popup/confirmModal/ConfirmModal';
import './TemplatesPage.scss';
import { TCreateTemplateDto, Template } from 'src/interfaces/template-interface';
import { createTemplate, deleteTemplate, getTemplates, updateTemplate } from 'src/stores/templates';
import TemplateModal from './components/TemplateModal';

const TemplatesPage = () => {
  const dispatch = useAppDispatch();
  const [templates, setTemplates] = useState<Template[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const fetchTemplates = async () => {
    setLoading(true);
    try {
      const response = await dispatch(getTemplates());
      if (response.payload && Array.isArray(response.payload)) {
        setTemplates(response.payload);
      } else {
        setTemplates([]);
      }
    } catch (error: any) {
      showErrorToast(error.message);
      setTemplates([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const handleCreateTemplate = async (values: TCreateTemplateDto) => {
    try {
      await dispatch(createTemplate(values));
      showSuccessToast('Template created successfully');
      setIsModalVisible(false);
      fetchTemplates();
    } catch (error: any) {
      showErrorToast(error.message);
    }
  };

  const handleUpdateTemplate = async (values: Partial<Template>) => {
    if (!selectedTemplate) return;
    try {
      await dispatch(updateTemplate({ id: selectedTemplate.id, ...values }));
      showSuccessToast('Template updated successfully');
      setIsModalVisible(false);
      fetchTemplates();
    } catch (error: any) {
      showErrorToast(error.message);
    }
  };

  const handleDeleteTemplate = async () => {
    if (!selectedTemplate) return;
    try {
      await dispatch(deleteTemplate(selectedTemplate.id));
      showSuccessToast('Template deleted successfully');
      setIsDeleteModalVisible(false);
      fetchTemplates();
    } catch (error: any) {
      showErrorToast(error.message);
    }
  };

  const columns: ColumnsType<Template> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => {
              setSelectedTemplate(record);
              setIsModalVisible(true);
            }}
          >
            Edit
          </Button>
          <Button
            type="link"
            danger
            onClick={() => {
              setSelectedTemplate(record);
              setIsDeleteModalVisible(true);
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="TemplatesPage">
      <div className="TemplatesPage__header">
        <BaseText type="heading">Templates</BaseText>
        <Button type="primary" onClick={() => setIsModalVisible(true)}>
          New Template
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={templates}
        loading={loading}
        rowKey="id"
      />

      <TemplateModal
        visible={isModalVisible}
        template={selectedTemplate}
        onCancel={() => {
          setIsModalVisible(false);
          setSelectedTemplate(null);
        }}
        onSubmit={(values) => {
          if (selectedTemplate) {
            handleUpdateTemplate(values);
          } else {
            handleCreateTemplate(values);
          }
        }}
      />

      <ConfirmModal
        open={isDeleteModalVisible}
        onCancelButton={() => setIsDeleteModalVisible(false)}
        onsubmit={handleDeleteTemplate}
        titleModal="Delete Template"
      >
        <div className="DeleteTemplateModal">
          <BaseText type="body1">Are you sure you want to delete this template?</BaseText>
        </div>
      </ConfirmModal>
    </div>
  );
};

export default TemplatesPage; 