import { createSlice } from '@reduxjs/toolkit';
import { Template } from 'src/interfaces/template-interface';
import * as asyncActions from './templates-actions';

interface ITemplatesState {
  templates: Template[];
  loading: boolean;
  error: string | null;
}

const initialState: ITemplatesState = {
  templates: [],
  loading: false,
  error: null,
};

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get Templates
    builder.addCase(asyncActions.getTemplates.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncActions.getTemplates.fulfilled, (state, action) => {
      state.templates = action.payload;
      state.loading = false;
    });
    builder.addCase(asyncActions.getTemplates.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || 'Failed to fetch templates';
    });

    // Create Template
    builder.addCase(asyncActions.createTemplate.fulfilled, (state, action) => {
      state.templates.push(action.payload);
    });

    // Update Template
    builder.addCase(asyncActions.updateTemplate.fulfilled, (state, action) => {
      const index = state.templates.findIndex((template) => template.id === action.payload.id);
      if (index !== -1) {
        state.templates[index] = action.payload;
      }
    });

    // Delete Template
    builder.addCase(asyncActions.deleteTemplate.fulfilled, (state, action) => {
      state.templates = state.templates.filter((template) => template.id !== action.payload);
    });
  },
});

export default templatesSlice; 