import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { ApiClient } from 'src/services/api-client';
import { showSuccessToast, showErrorToast } from 'src/components/toast/Toast';
import { Template, TCreateTemplateDto, TUpdateTemplateDto } from 'src/interfaces/template-interface';
import { ETemplateActions } from './templates-constants';
import ResponseError from 'src/interfaces/error-response-interface';

export const getTemplates = createAsyncThunk(
  ETemplateActions.GET_TEMPLATES,
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<Template[]> = await ApiClient.get('/api/v1/templates/all');
      return response.data;
    } catch (error: any) {
      const message = (error as ResponseError).message;
      showErrorToast(message);
      return rejectWithValue(error.data);
    }
  }
);

export const createTemplate = createAsyncThunk(
  ETemplateActions.CREATE_TEMPLATE,
  async (template: TCreateTemplateDto, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<Template> = await ApiClient.post('/api/v1/templates', template);
      return response.data;
    } catch (error: any) {
      const message = (error as ResponseError).message;
      showErrorToast(message);
      return rejectWithValue(error.data);
    }
  }
);

export const updateTemplate = createAsyncThunk(
  ETemplateActions.UPDATE_TEMPLATE,
  async ({ id, ...template }: { id: string } & TUpdateTemplateDto, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<Template> = await ApiClient.put(`/api/v1/templates/${id}`, template);
      return response.data;
    } catch (error: any) {
      const message = (error as ResponseError).message;
      showErrorToast(message);
      return rejectWithValue(error.data);
    }
  }
);

export const deleteTemplate = createAsyncThunk(
  ETemplateActions.DELETE_TEMPLATE,
  async (id: string, { rejectWithValue }) => {
    try {
      await ApiClient.delete(`/api/v1/templates/${id}`);
      return id;
    } catch (error: any) {
      const message = (error as ResponseError).message;
      showErrorToast(message);
      return rejectWithValue(error.data);
    }
  }
); 