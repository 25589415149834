import {
  FileTextOutlined,
  ProfileOutlined,
  ScheduleOutlined,
  AlertOutlined,
  SolutionOutlined,
  TeamOutlined,
  BookOutlined,
  FormOutlined,
  SafetyOutlined,
  MedicineBoxOutlined,
  BarChartOutlined,
  FileSearchOutlined,
  MessageOutlined,
  SendOutlined,
} from '@ant-design/icons';

export const SUPPORTED_TEMPLATE_ICONS = [
  {
    value: 'FileTextOutlined',
    label: 'File Text',
    icon: FileTextOutlined,
  },
  {
    value: 'ProfileOutlined',
    label: 'Profile',
    icon: ProfileOutlined,
  },
  {
    value: 'ScheduleOutlined',
    label: 'Schedule',
    icon: ScheduleOutlined,
  },
  {
    value: 'AlertOutlined',
    label: 'Alert',
    icon: AlertOutlined,
  },
  {
    value: 'SolutionOutlined',
    label: 'Solution',
    icon: SolutionOutlined,
  },
  {
    value: 'TeamOutlined',
    label: 'Team',
    icon: TeamOutlined,
  },
  {
    value: 'BookOutlined',
    label: 'Book',
    icon: BookOutlined,
  },
  {
    value: 'FormOutlined',
    label: 'Form',
    icon: FormOutlined,
  },
  {
    value: 'SafetyOutlined',
    label: 'Safety',
    icon: SafetyOutlined,
  },
  {
    value: 'MedicineBoxOutlined',
    label: 'Medicine Box',
    icon: MedicineBoxOutlined,
  },
  {
    value: 'BarChartOutlined',
    label: 'Bar Chart',
    icon: BarChartOutlined,
  },
  {
    value: 'FileSearchOutlined',
    label: 'File Search',
    icon: FileSearchOutlined,
  },
  {
    value: 'MessageOutlined',
    label: 'Message',
    icon: MessageOutlined,
  },
  {
    value: 'SendOutlined',
    label: 'Send',
    icon: SendOutlined,
  },
];

export const DEFAULT_COLORS = [
  '#FF5733', // Coral
  '#33FF57', // Lime
  '#3357FF', // Blue
  '#FF33F6', // Pink
  '#33FFF6', // Cyan
  '#F6FF33', // Yellow
  '#FF3333', // Red
  '#33FF33', // Green
  '#3333FF', // Deep Blue
  '#7B2CBF', // Purple
  '#2C698D', // Ocean Blue
  '#272727', // Dark Gray
]; 